import React from 'react'
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl"

import { Layout } from '../components/Layout'
import SEO from '../components/SEO/SEO'

import { ContactUs } from '../sections/ContactUs'
import Works from '../sections/WorksList'

import seoImage from "../../static/images/seo_image.png"

export const query = graphql`
  query{
    cms {
      works(pagination: {limit: 200}, filters: {Show_main_works_page: {eq: true}} ){
        data {
          id
          attributes {
            Categories
            Industry
            Thumbnail_Image {
              data {
                attributes {
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
            Project_Title
            Subtitle
            Client_Logo {
              data {
                attributes {
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
            Project_Website
            Priority
            Main_Project_Name
          }
        }
      }
    }
  }
`;

const WorkLanding = ({data, location}: any) => {
  const intl = useIntl();
  //console.log(location.state.category,"category serviceeeee")
  return (
    <Layout background="#F8F8F8" initialTextColor='#000000' landing="work">
      <SEO 
        pageTitle={intl.formatMessage({ id: "seoWorkTitle" })}
        description={intl.formatMessage({ id: "seoWorkDescription" })}
        twitterSite="@wearepsh"
        twitterTitle="Providing Digital Transformation | We Are PSh"
        ogUrl="https://wearepsh.com/work"
        ogDescription="PSh is a digital agency that creates experiences for brands and customers, merging creativity with technology to create innovative and personalized solutions."
       ogImage={"https://wearepsh.com/images/seo_image.png"} 
      />
      <Works worksList={data.cms.works.data} serviceCategory={location.state?.category}/>
      <div id="contactwork">
        <ContactUs />
      </div>
    </Layout>
  )
}

export default WorkLanding
